// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Preferences.vue render when created  1`] = `
<div
  class="preferences-wrapper"
  section="[object Object]"
>
  <div
    class="pw-language"
  >
    <div
      class="settings-card"
    >
      <div
        class="settings-card__heading"
      >
        <h5>
          System Language
        </h5>
         
        <div
          class="sc-heading__actions"
        >
          <span
            class="sc-heading__actions--edit"
          >
            Edit
          </span>
        </div>
      </div>
       
      <div
        class="sc-content"
      >
        <div
          class="sc-content__content"
        >
          <div>
            <ul
              class="md-list"
            >
              <li>
                <label>
                  Modify your language settings.
                </label>
                 
                <p>
                  English
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
